import { useState, useEffect } from "react";
import axios from "axios";
import commaNumber from "comma-number";
import Chart from "react-apexcharts";

const Dashboard = () => {
  const [opTrx, setOpTrx] = useState({});
  const [seTrx, setSeTrx] = useState({});

  const getTrx = async () => {
    const { data } = await axios.get('/transactions');
    const groupedTransactions = data.reduce((acc, transaction) => {
      if (!acc[transaction.transaction_type]) {
        acc[transaction.transaction_type] = [];
      }
      acc[transaction.transaction_type].push(transaction);
      return acc;
    }, {});

    setOpTrx({
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        style: {
          fontFamily: "sam",
          fontWeight: 600,
          fontSize: '12px',
        },
        text: 'مخطط المعاملات',
        align: 'center'
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      xaxis: {
        categories: data.map((item) => new Date(item.created ? item.created : 0).toLocaleDateString('ar-iq', { month: 'short', day: 'numeric' })),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${commaNumber(val)} طن `
          },
        },
      },
      noData: { text: "لا توجد إحصاءات." }
    });

    const seriesData = Object.keys(groupedTransactions).map((key) => {
      const transactions = groupedTransactions[key];
      const data = transactions.map((transaction) => transaction.qty ? transaction.qty : 0);
      return {
        name: key,
        data: data
      };
    });
    setSeTrx(seriesData);
  }

  const [stats, setStats] = useState({});
  const getStats = async () => {
    const { data } = await axios.get('/stats');
    setStats(data);
  }

  useEffect(() => {
    getTrx();
    getStats();
  }, []);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-md-12">
          <div className="row mb-4">
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">اجمالي الربح</h6>
                  </div>
                  <h4 className="mb-2 m-1">{commaNumber(parseInt(stats.profit))} $</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>اجمالي الربح من صادرة و الواردة</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">ربح المتوفع</h6>
                  </div>
                  <h4 className="mb-2 m-1">{commaNumber(parseInt(stats.expected_profit))} $</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>تقدير الربح من كافة المواد</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">مواد التالفة</h6>
                  </div>
                  <h4 className="mb-2 m-1">{stats.spoilage ? commaNumber(stats.spoilage) : 0} كغ</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>مجموع كمية التالفة من المواد</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">عدد الوكلاء</h6>
                  </div>
                  <h4 className="mb-2 m-1">{commaNumber(stats.clients)} وكيل</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>عدد الوكلاء من صادرة و التصدير</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">رصيد الصندوق</h6>
                  </div>
                  <h4 className="mb-2 m-1">{stats.fund_balance ? commaNumber(parseInt(stats.fund_balance)) : 0} $</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>اجمالي رصيد الصنوق من كافة الوكلاء </span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">كمية المتبقية</h6>
                  </div>
                  <h4 className="mb-2 m-1">{commaNumber(stats.product_balance)} كغ</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>كمية المتبقية من المشتريات</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">ديون الوكلاء</h6>
                  </div>
                  <h4 className="mb-2 m-1">{stats.client_balance ? commaNumber(parseInt(stats.client_balance)) : 0} $</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>مجموع الديون عند الوكلاء</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h6 className="card-title mb-0">انواع المواد</h6>
                  </div>
                  <h4 className="mb-2 m-1">{commaNumber(stats.products)} نوع</h4>
                  <div className="text-center">
                    <p className="text-muted"><span>عدد المواد التي تتجار بها</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="card">
            <div className="card-body">
              <Chart options={opTrx} series={seTrx} type='area' height={330} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
