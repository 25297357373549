import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const Transactions = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/transactions');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 6, 7]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [3, 4, 5, 8, 9, 10]
                                }

                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })

                        var api = window.$('.dt-tbl').DataTable();
                        var total_qty = 0;
                        var total_amount = 0;
                        new window.$.fn.dataTable.FixedHeader(api);

                        var totalqty = api.column(3, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                        var totalamount = api.column(4, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                        $('#total_amount').html(`${commaNumber(parseFloat(total_amount))} $`);

                        window.$('.dt-tbl').DataTable().on('search.dt', function () {
                            var total_qty = 0;
                            var total_amount = 0;

                            var totalqty = api.column(3, { search: 'applied' }).data()
                            totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                            var totalamount = api.column(4, { search: 'applied' }).data()
                            totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                            $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                            $('#total_amount').html(`${commaNumber(parseFloat(total_amount))} $`);

                        });
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">المعاملات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تقارير المعاملات (الصادرات و الواردات)</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>اسم الوكيل</th>
                                        <th>نوع الحمل</th>
                                        <th>السعر</th>
                                        <th>الكمية</th>
                                        <th>مجموع المبلغ</th>
                                        <th>نوع العملية</th>
                                        <th>اسم السائق</th>
                                        <th>رقم السيارة</th>
                                        <th>الملاحظة</th>
                                        <th>التاريخ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td> <Link to={`/profile/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link> </td>
                                            <td>{item.product_name}</td>
                                            <td>{commaNumber(parseFloat(item.price))} {item.currency}</td>
                                            <td>{commaNumber(item.qty)}</td>
                                            <td>{commaNumber(parseFloat(item.total))} {item.currency}</td>
                                            <td> <span className={`badge ${item.transaction_type === 'شراء' ? 'bg-success w-50' : 'bg-danger w-50'}`}>{item.transaction_type}</span> </td>
                                            <td>{item.driver_name}</td>
                                            <td>{item.plate_number}</td>
                                            <td>{item.note ? item.note : "-"}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع الوزن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 كغ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع المبلغ</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transactions;