import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";
import Swal from "sweetalert2";

const Purchases = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/view_purchases');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',

                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 6, 9]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [3, 4, 5, 7, 8]
                                }

                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })

                        var api = window.$('.dt-tbl').DataTable();
                        var total_qty = 0;
                        var total_amount = 0;
                        new window.$.fn.dataTable.FixedHeader(api);

                        var totalqty = api.column(4, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                        var totalamount = api.column(5, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                        $('#total_amount').html(`${commaNumber(parseFloat(total_amount))} $`);

                        window.$('.dt-tbl').DataTable().on('search.dt', function () {
                            var total_qty = 0;
                            var total_amount = 0;

                            var totalqty = api.column(4, { search: 'applied' }).data()
                            totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                            var totalamount = api.column(5, { search: 'applied' }).data()
                            totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                            $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                            $('#total_amount').html(`${commaNumber(parseFloat(total_amount))} $`);

                        });
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [client, setClient] = useState([]);
    const getClient = async () => {
        const { data } = await axios.get('/traders');
        setClient(
            data.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            })
        );
    }

    const [product, setProduct] = useState([]);
    const getProduct = async () => {
        const { data } = await axios.get('/products');
        setProduct(
            data.map((item) => {
                return {
                    value: item.id,
                    label: item.product_name,
                };
            })
        );
    }

    useEffect(() => {
        getData();
        getProduct();
        getClient();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);



    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">المشتريات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة المشتريات</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">اضافة جديدة</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> شراء جديد</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/purchases`, data).then(res => {
                                                        if (res.data !== 'done') {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            getData();
                                                            toast.success('تمت الاضافة بنجاح');
                                                            e.target.reset();
                                                            window.$('#new').modal('hide');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="client_id" className="form-label">الوكيل</label>
                                                                    <Select options={client} name="client_id" placeholder="اختر الوكيل" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="product_id" className="form-label">المادة</label>
                                                                    <Select options={product} name="product_id" placeholder="اختر المادة" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="qty" className="form-label">الكمية</label>
                                                                    <input type="number" className="form-control" step={"any"} name="qty" placeholder="الكمية" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="price" className="form-label">السعر (كيلو)</label>
                                                                    <input type="number" className="form-control" step={"any"} name="price" placeholder="السعر" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_name" className="form-label"> اسم السائق</label>
                                                                    <input type="text" className="form-control" name="driver_name" placeholder=" اسم السائق" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="plate_number" className="form-label"> رقم السيارة</label>
                                                                    <input type="text" className="form-control" name="plate_number" placeholder=" رقم السيارة" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="mb-3">
                                                                    <label htmlFor="note" className="form-label">ملاحظة</label>
                                                                    <input type="text" className="form-control" name="note" placeholder="ملاحظة" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                        <button type="submit" className="btn btn-primary"> اضافة</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>اسم الوكيل</th>
                                        <th>نوع الحمل</th>
                                        <th>السعر</th>
                                        <th>الكمية</th>
                                        <th>مجموع المبلغ</th>
                                        <th>اسم السائق</th>
                                        <th>رقم السيارة</th>
                                        <th>الملاحظة</th>
                                        <th>التاريخ</th>
                                        <th>الخيارات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={item.transaction_id}>
                                            <td>{index + 1}</td>
                                            <td> <Link to={`/profile/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link> </td>
                                            <td>{item.product_name}</td>
                                            <td>{commaNumber(parseFloat(item.price))} {item.currency}</td>
                                            <td>{commaNumber(item.qty)} كغ</td>
                                            <td>{commaNumber(parseFloat(item.total))} {item.currency}</td>
                                            <td>{item.driver_name}</td>
                                            <td>{item.plate_number}</td>
                                            <td>{item.note ? item.note : "-"}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#update${item.transaction_id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                    Swal.fire({
                                                        title: 'تحذير',
                                                        text: "هل انت متاكد من حذف هذا العنصر ؟",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'نعم',
                                                        cancelButtonText: 'لا',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            axios.delete(`/purchases/${item.transaction_id}`).then(() => {
                                                                getData();
                                                            })
                                                        }
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`update${item.transaction_id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">تعديل الشراء</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/purchases/${item.transaction_id}`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    getData();
                                                                    toast.success('تم تعديل الشراء بنجاح');
                                                                    window.$(`#update${item.transaction_id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="client_id" className="form-label">الوكيل</label>
                                                                            <Select options={client} name="client_id" placeholder="اختر الوكيل" value={client.find(option => option.value === item.client_id)} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="product_id" className="form-label">المادة</label>
                                                                            <Select options={product} name="product_id" placeholder="اختر المادة" value={product.find(option => option.value === item.product_id)} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="qty" className="form-label">الكمية</label>
                                                                            <input type="number" className="form-control" step={"any"} name="qty" placeholder="الكمية" defaultValue={item.qty} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="price" className="form-label">السعر (كيلو)</label>
                                                                            <input type="number" className="form-control" step={"any"} name="price" placeholder="السعر" defaultValue={item.price} required />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="driver_name" className="form-label"> اسم السائق</label>
                                                                            <input type="text" className="form-control" name="driver_name" placeholder=" اسم السائق" defaultValue={item.driver_name} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="plate_number" className="form-label"> رقم السيارة</label>
                                                                            <input type="text" className="form-control" name="plate_number" placeholder=" رقم السيارة" defaultValue={item.plate_number} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                                            <input type="text" className="form-control" name="note" placeholder="ملاحظة" defaultValue={item.note} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                                <button type="submit" className="btn btn-primary"> تحديث</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع الوزن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 كغ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع المبلغ</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Purchases;