import { useEffect, useState } from "react";
import axios from "axios";
import commaNumber from "comma-number";
import { useParams } from "react-router-dom";
import $ from "jquery";

const Profile = () => {
    const id = useParams().id;

    const [profile, setProfile] = useState({});
    const getProfile = async () => {
        const { data } = await axios.get(`/clients/${id}`);
        if (data.length > 0) {
            setProfile(data[0]);
        } else {
            setProfile(data);
        }

    }

    const [profileBlance, setProfileBalance] = useState({});
    const getStats = async () => {
        const { data } = await axios.get(`/profileBalance/${id}`);
        setProfileBalance(data);
    }

    const [profileTrx, setProfileTrx] = useState([]);
    const getTrx = async () => {
        const { data } = await axios.get(`/transactions/${id}`);
        setProfileTrx(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }

    }

    const [fund, setFund] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/profileFund/${id}`);
        setFund(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl2')) {
                        window.$('.dt-tbl2').DataTable();
                    } else {
                        window.$('.dt-tbl2').DataTable();
                    }
                }
            });
            $('.py-5').hide();
            $('.dt-tbl2').show();
        } else {
            $('.py-5').hide();
            $('.dt-tbl2').show();
        }
    }

    useEffect(() => {
        getProfile();
        getStats();
        getTrx();
        getData();
    }, []);

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 mb-5">
                    <div className="card">
                        <div className="card-body bg-light">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">معلومات الحساب</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="text-muted">الاسم</p>
                                        </div>
                                        <div className="col-md-8">
                                            <p>{profile.full_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="text-muted">رقم الهاتف</p>
                                        </div>
                                        <div className="col-md-8">
                                            <p>{profile.phone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="text-muted">العنوان</p>
                                        </div>
                                        <div className="col-md-8">
                                            <p>{profile.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row mb-4">
                        <div className="col-md-4 text-center">
                            <div className="card bg-warning">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0"> ميزان الصندوق</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{commaNumber(parseFloat(profileBlance.fund_balance))} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span>ميزان الرصيد من السحبات والشحنات</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card bg-primary">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0">مبلغ المعاملات</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{commaNumber(parseFloat(profileBlance.transactions_balance))} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span>اجمالي المبلغ المعاملات</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0">رصيد الحساب</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{commaNumber(parseFloat(profileBlance.client_balance))} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span> ميزان الحساب</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6 className="card-title">قائمة تمويلات</h6>
                                        </div>
                                    </div>
                                    <div className="container py-5">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <div className="skeleton_wave" />
                                                    </th>
                                                    <th>
                                                        <div className="skeleton_wave" /> </th>
                                                    <th><div className="skeleton_wave" /></th>
                                                    <th><div className="skeleton_wave" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table className="table table-sm dt-tbl2" style={{ width: "100%", display: "none" }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>المبلغ</th>
                                                <th>العملية</th>
                                                <th>ملاحظة</th>
                                                <th>التاريخ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fund && fund.map((item, index) => (
                                                <tr key={item.cid}>
                                                    <td>{index + 1}</td>
                                                    <td>{commaNumber(parseFloat(item.amount))} {item.currency}</td>
                                                    <td>{item.method === "شحن" ? (<span className="badge bg-primary">{item.method}</span>) : (<span className="badge bg-danger">{item.method}</span>)}</td>
                                                    <td>{item.note}</td>
                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {profile && profile.trade_type !== "شركة" &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-title">تقارير المعاملات (الصادرات و الواردات)</h6>
                                            </div>
                                        </div>
                                        <div className="container py-4">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            <div className="skeleton_wave" />
                                                        </th>
                                                        <th>
                                                            <div className="skeleton_wave" /> </th>
                                                        <th><div className="skeleton_wave" /></th>
                                                        <th><div className="skeleton_wave" /></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                        <td><div className="skeleton_wave rounded" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>نوع الحمل</th>
                                                    <th>السعر</th>
                                                    <th>الكمية</th>
                                                    <th>مجموع المبلغ</th>
                                                    <th>نوع العملية</th>
                                                    <th>اسم السائق</th>
                                                    <th>رقم السيارة</th>
                                                    <th>الملاحظة</th>
                                                    <th>التاريخ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {profileTrx && profileTrx.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.product_name}</td>
                                                        <td>{commaNumber(item.price)} {item.currency}</td>
                                                        <td>{commaNumber(item.qty)}</td>
                                                        <td>{commaNumber(item.total)} {item.currency}</td>
                                                        <td> <span className={`badge ${item.transaction_type === 'شراء' ? 'bg-success' : 'bg-danger'}`}>{item.transaction_type}</span> </td>
                                                        <td>{item.driver_name}</td>
                                                        <td>{item.plate_number}</td>
                                                        <td>{item.note ? item.note : "-"}</td>
                                                        <td>{new Date(item.created).toLocaleDateString()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}

export default Profile;